export default [
  {
    "id": 1,
    "name": "Settlement Supplies",
    "desc": "Help moving the Settlement Supplies from the old underground tunnels.",
    "exp": 3200,
    "money": 250,
    "duration": 15000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      1,
      1,
      1,
      1,
      2,
      2,
      3
    ],
    "rewardChance": 0.055,
    "armorReward": [
      1,
      1,
      1,
      2
    ],
    "armorRewardChance": 0.025,
    "levelRequirement": 1,
    "lat": 539.7513037388919,
    "lon": 887.9123598579757,
    "img": "settlement_supplies"
  },
  {
    "id": 2,
    "name": "Search for Settlement Location",
    "desc": "This looks like a fine area to place our Settlement, Lets search the area first.",
    "exp": 1500,
    "money": 250,
    "duration": 40000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      3,
      1,
      1,
      2
    ],
    "rewardChance": 0.1,
    "armorReward": [
      1,
      1,
      1,
      2,
      2,
      3
    ],
    "armorRewardChance": 0.1,
    "levelRequirement": 2,
    "lat": 443.6058302383243,
    "lon": 595.9267881227183,
    "img": "search_location"
  },
  {
    "id": 3,
    "name": "Clock Tower",
    "desc": "Seek shelter for the night near Clock Tower.",
    "exp": 2000,
    "money": 250,
    "duration": 85000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      2,
      2,
      2,
      3,
      4,
      1
    ],
    "rewardChance": 0.05,
    "armorReward": [
      1,
      2,
      2,
      3,
      1
    ],
    "armorRewardChance": 0.01,
    "levelRequirement": 3,
    "lat": 431.20445333692595,
    "lon": 1505.115364599656,
    "img": "clock_tower"
  },
  {
    "id": 4,
    "name": "Strange Noises!",
    "desc": "Jack heard something that sounded like a *Grum Ahh Grup* Not sure what to think about that...",
    "exp": 4000,
    "money": 1000,
    "duration": 620000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      2,
      2,
      2,
      3,
      4,
      3,
      3,
      1,
      6,
      7
    ],
    "rewardChance": 0.7,
    "armorReward": [
      1,
      1,
      2,
      2,
      3,
      4,
      6
    ],
    "armorRewardChance": 0.02,
    "levelRequirement": 4,
    "lat": 577.1120006637091,
    "lon": 405.3674957006255,
    "img": "strange_noises"
  },
  {
    "id": 5,
    "name": "Cleaning out the Library",
    "desc": "Before we can make this a more permament location for our Settlement, we need to clean up this mess first!",
    "exp": 750,
    "money": 1200,
    "duration": 60000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      4,
      5,
      2,
      1,
      1,
      1
    ],
    "rewardChance": 0.03,
    "armorReward": [
      2,
      1,
      1,
      1,
      4,
      3
    ],
    "armorRewardChance": 0.01,
    "levelRequirement": 5,
    "lat": 741.0071055888336,
    "lon": 1581.5410583527942,
    "img": "library_cleanup"
  },
  {
    "id": 6,
    "name": "Last Supply Run!",
    "desc": "Last Supply Run from The Underground Tunnels has come surfaced, put them inside into the storage area.",
    "exp": 2500,
    "money": 500,
    "duration": 90000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      6,
      7,
      2,
      2,
      1,
      1,
      1,
      1
    ],
    "rewardChance": 0.02,
    "armorReward": [
      3,
      2,
      2,
      1,
      1,
      1,
      1
    ],
    "armorRewardChance": 0.06,
    "levelRequirement": 7,
    "lat": 397.75944603183245,
    "lon": 898.0748849299532,
    "img": "supply_run"
  },
  {
    "id": 7,
    "name": "Cleaning out the Library Part 2",
    "desc": "So Jimmy was careless and dropped a live grenade and made a mess in the Library, kill himself in the process!",
    "exp": 2500,
    "money": 100,
    "duration": 30000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      2,
      6,
      1,
      1
    ],
    "rewardChance": 0.08,
    "armorReward": [
      4,
      2,
      1,
      1,
      1,
      1,
      2
    ],
    "armorRewardChance": 0.03,
    "levelRequirement": 7,
    "lat": 666.2673346912874,
    "lon": 765.9514857772996,
    "img": "library_cleanup_2"
  },
  {
    "id": 8,
    "name": "Radioactive Wasteland",
    "desc": "Venture into the Radioactive Wasteland and collect rare resources.",
    "exp": 8000,
    "money": 2500,
    "duration": 1600000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      4,
      7,
      2,
      1,
      1,
      1,
      2,
      2
    ],
    "rewardChance": 0.15,
    "armorReward": [
      3,
      4,
      5,
      1,
      1,
      1,
      2,
      2
    ],
    "armorRewardChance": 0.1,
    "levelRequirement": 9,
    "lat": 435.6223827928487,
    "lon": 640.5184618239955,
    "img": "radioactive_wasteland"
  },
  {
    "id": 9,
    "name": "Overgrown Village",
    "desc": "Explore the Overgrown Village and recover the lost artifacts.",
    "exp": 12000,
    "money": 150,
    "duration": 1200000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      2,
      1,
      1,
      3,
      5,
      5
    ],
    "rewardChance": 0.05,
    "armorReward": [
      5,
      3,
      1
    ],
    "armorRewardChance": 0.02,
    "levelRequirement": 10,
    "lat": 453.9046136197555,
    "lon": 337.24037159852486,
    "img": "overgrown_village"
  },
  {
    "id": 10,
    "name": "Shipment of Supplies",
    "desc": "Search the Shipment of Supplies for supplies and weapons.",
    "exp": 14000,
    "money": 5000,
    "duration": 1280000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      3,
      1,
      1,
      3,
      5,
      7
    ],
    "rewardChance": 0.7,
    "armorReward": [
      5,
      3,
      3,
      1,
      1,
      1,
      1,
      1,
      1
    ],
    "armorRewardChance": 0.45,
    "levelRequirement": 12,
    "lat": 603.0567069681547,
    "lon": 1303.9951754454275,
    "img": "supply_shipment"
  },
  {
    "id": 11,
    "name": "Trip to Alantic City",
    "desc": "We found a signal that leads to Alantic City.",
    "exp": 9000,
    "money": 2000,
    "duration": 1250000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      3,
      1,
      1,
      3,
      5,
      7
    ],
    "rewardChance": 0.25,
    "armorReward": [
      5,
      3,
      3,
      1,
      1,
      1,
      1,
      1,
      1
    ],
    "armorRewardChance": 0.75,
    "levelRequirement": 15,
    "lat": 763.911934267905,
    "lon": 1582.1162100102758,
    "img": "atlantic_city"
  },
  {
    "id": 12,
    "name": "Hidden Stashes in The Warehouse",
    "desc": "We are looking for supplies in old dock, with a lot of warehouses around.",
    "exp": 3500,
    "money": 450,
    "duration": 450000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      21,
      21,
      21,
      21,
      20
    ],
    "rewardChance": 0.05,
    "armorReward": [
      11,
      11,
      11,
      11,
      10
    ],
    "armorRewardChance": 0.05,
    "levelRequirement": 15,
    "lat": 514.3955416614195,
    "lon": 899.4427564846104,
    "img": "warehouse_stashes"
  },
  {
    "id": 13,
    "name": "Into the Acid Rain",
    "desc": "The Acid Rain is destroying everything! We need find shelther and wait it out! ",
    "exp": 12000,
    "money": 800,
    "duration": 1200000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      3,
      1,
      2,
      1
    ],
    "rewardChance": 0.2,
    "armorReward": [
      1,
      2,
      1,
      3
    ],
    "armorRewardChance": 0.2,
    "levelRequirement": 17,
    "lat": 415.0698031999426,
    "lon": 1078.113264360958,
    "img": "acid_rain"
  },
  {
    "name": "New Exploration Quest",
    "desc": "Explore a dangerous area for valuable resources.",
    "exp": 2500,
    "money": 250,
    "duration": 45000,
    "progress": 0,
    "state": "not-started",
    "claimed": false,
    "disabled": false,
    "reward": [
      2,
      2,
      3,
      4
    ],
    "rewardChance": 0.1,
    "armorReward": [
      2,
      3,
      4
    ],
    "armorRewardChance": 0.05,
    "levelRequirement": 1,
    "img": "highway",
    "id": 14,
    "lat": 604.0355679372634,
    "lon": 1147.0266039703497
  }
];